@import '../../../assets/scss/variable';

.progress {
  height: 26px !important;
  border-radius: 2em !important;
  &-bar {
    border-radius: 2em !important;
    background: linear-gradient(
      60deg,
      #0d6efd 7.63%,
      #7aafbb 92.37%
    ) !important;
  }
}

.zestur-card {
  h2 {
    color: $black-900;
    font-size: 26px !important;
    font-weight: $font-medium !important;
  }

  .progress-title {
    color: $dark-grey;
    font-size: 16px;
    font-weight: $font-normal;
  }

  .info {
    &-button {
      color: $black-900;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: $font-medium;
    }

    &-title {
      color: $black-900;
      font-family: 'Poppins';
      font-size: 20px;
      font-weight: $font-normal;
    }

    &-subtitle {
      font-family: 'Poppins';
      color: $dark-grey;
      font-size: 18px;
      font-weight: $font-medium;
    }
  }
}

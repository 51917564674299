@import '../../../../assets/scss/variable';
@import '../../../../assets/scss/breakpoint/breakpoints-mixins';

.vacancy-edit-input {
  border-radius: 8px !important;
  border: 1px solid $dark-blue !important;
  padding: 10px 21px !important;

  font-family: 'Poppins' !important;
  font-size: 16px !important;
  font-weight: $font-semi-bold !important;
  color: $main-black !important;
}

.offer-edit {
  &__autocomplete {
    input {
      padding: 10px 21px !important;
      font-family: 'Poppins' !important;
      font-size: 16px !important;
      font-weight: $font-semi-bold !important;
    }
  }

  &-row {
    margin: 0 48px !important;

    @include tablet-breakpoint {
      margin: 0 1rem !important;
    }

    @include mobile-breakpoint {
      margin: 0 !important;
    }
  }
}

.autocomplete-modal-input {
  &.offer-edit {
    input {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import './assets/scss/variable';

html,
body {
  font-family: 'Poppins', sans-serif;
}

html, body, #root, .App, .home {
  height: 100%;
}

.App {
  overflow-x: hidden;
}

// 🔹 Google Login Button Styling
.google-login-button {
  display: flex; // Ensures proper alignment of logo and text
  align-items: center; // Vertically aligns text and logo
  justify-content: center; // Centers everything inside
  gap: 10px; // Adds space between the logo and text

  width: 100%; // Makes it responsive
  max-width: 300px; // Adjust based on design needs
  padding: 12px 20px; // More balanced padding
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  color: #444; // Slightly darker text for better contrast

  // 🔹 Hover effect for better UX
  &:hover {
    background: #f5f5f5;
  }
}

// 🔹 Google Logo Styling
.google-logo {
  width: 24px;
  height: 24px;
}

.bg-image.login {
  background: white !important;  /* Removes the background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-primary {
  background-color: #011640 !important; /* Dark navy blue */
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid white !important;
  padding: 10px 30px;
  border-radius: 30px 30px 0 30px !important; /* Rounded corners except bottom right */
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin:1.5rem 0;
  font-size:23.04px;
}

h1 {
  font-size: 2.98rem;
}

h2 {
  font-size: 2.48rem;
}

h3 {
  font-size: 2.07rem;
}

h4 {
  font-size: 1.72rem;
}

h5 {
  font-size: 1.44rem;
}

h6 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
}

.submitbtn.candidate {
  background-color: $candidate !important;
  border-color: $candidate-border !important;
}

.submitbtn.company {
  background: $company !important;
  border-color: $company-border !important;
}
@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';
.notificationMenu {
  position: fixed;
  right: 0;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 18px 24px;
  width: 448px;
  padding-bottom: 2rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 100;

  @include mobile-breakpoint {
    top: 0;
    width: 100%;
    height: 100vh;
  }
}

.closeIcon {
  width: 2rem;
  height: 2rem;
  margin-top: 6px;
  margin-right: 6px;

  @include mobile-breakpoint {
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

.container {
  padding: 0 42px;
}

.title {
  color: var(--blue, $dark-blue);
  font-family: 'Poppins';
  font-size: 25px;
  font-style: normal;
  font-weight: $font-medium;
  line-height: normal;
}

.notificationContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@import '../../../assets/scss/variable';

.table-filter-button {
  color: $graylight;
  border: none;
  border-bottom: 2px solid $graylight;
  padding-top: 7px;
  padding-bottom: 14px;
  background-color: transparent;
  text-align: center;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: $font-medium;

  &.active {
    border-color: $light-blue;
    color: $light-blue;
  }
}

@import '../../assets/scss/variable';

.btn-outline-color {
    border-color: $dark-blue !important;
    color: $dark-blue !important;
}
.card-border-primary {
    border: 1px solid $dark-blue !important;
}
.btn-icon-add {
    background: transparent;
    border: 2px solid $dark-blue;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    color: $dark-blue;
    display: flex;
    align-items: center;
    font-size: 3em;
    justify-content: center;
    span {
        margin-top: 2px;
    }
}

.primary-text {
    color: $dark-blue !important;
}

.modal-header {
    display: flex !important;
    align-items: center;
    justify-content: center !important;
    margin: 0 !important;
    border: none !important;
    .btn-close {
        margin: 0 !important;
        position: absolute;
        right: 0;
        top: 0;
    }
    .modal-title h4 {
        text-align: center !important;
    }
}

.modal-header-color {
    background-color: $dark-blue;
    color: white;
}

.primary-btn-color {
    background-color: $dark-blue !important;
    border-color: $dark-blue !important;
}

@each $size in $font-size-list {
    .f-#{$size} {
        font-size: #{$size}px !important;
    }
}

.btn-gray {
    background: lighten(#252525, 70%)  !important;
}
.zesture-badge {
    background-color: $light-blue !important;
    padding: .8em 1.4em !important;
}

.general-heading {
    font-size: 20px !important;
    color: $dark-blue;
}

.offer-heading {
    font-size: 20px !important;
    font-weight: $font-normal !important;
    color: $dark-blue-alt !important;
}

.offer-form-label {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: $font-medium;
}

.page-heading-2 {
    color: $dark-blue !important;
    font-size: 28px;
}
.general-sub-heading {
    font-size: 14px !important;
    color: $graylight;
}

.add-btn {
    width: 30px;
    height: 30px;
    border-radius: 1px solid $dark-blue;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: transparent;
    color: $dark-blue;
}

 .breaklines,
 .details__content > p,
 .Toastify__toast-body {
     white-space: pre-line;
 }
